import React, { useEffect, useState } from "react";
import styles from "./address.module.scss";
import { ReactComponent as Copy } from "@assets/svg/copy.svg";
import { ReactComponent as Copyied } from "@assets/svg/copyiedIcon.svg";
import { ReactComponent as QR } from "@assets/svg/qr_white.svg";
import useWindowSize from "@root/src/assets/svg/hooks/useWindowSize";
import { shortenAddress } from "@root/src/utils";

export const Address = ({ address }: { address: string | undefined }) => {
  const [isClicked, setClick] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (isClicked === true) {
      setTimeout(() => setClick(false), 1000);
    }
  }, [isClicked]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleCopyId = () => {
    navigator.clipboard.writeText(address || "");
  };
  return (
    <article className={styles.container}>
      <label className={styles.label}>Wallet address</label>
      <article className={styles.wrapper}>
        <article
          data-clicked={isClicked}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            setClick(!isClicked);
            handleCopyId();
          }}
          className={`${styles.address_wrapper} ${
            isClicked ? styles.clicked : null
          }`}
        >
          {(isHovered || isClicked) && (
            <article className={styles.text_copy}>
              {isClicked ? "Copied" : isHovered ? "Copy address" : null}
            </article>
          )}
          <article className={styles.address}>
            {width < 600 ? shortenAddress(address ?? "") : address}
          </article>
          <figure className={styles.copy_icon}>
            {isClicked ? <Copyied /> : <Copy />}
          </figure>
        </article>
        <article className={styles.qr}>
          <QR />
        </article>
      </article>
    </article>
  );
};
