import React, { useEffect, useState } from "react";
import styles from "./App.module.scss";
import "./styles/global.module.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Networks } from "@pages/networks/Networks";
import { Home } from "@pages/home/Home";
import Layout from "./app/components/layout/Layout";
import NotFound from "./app/pages/not_found/NotFound";

function App() {
  const [pathname, setPathName] = useState("");
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location]);

  const showImage = pathname === "/";

  return (
    <div className={styles.App} data-image={`${showImage}`}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/address/:address" element={<Networks />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
